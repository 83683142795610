import { exists } from '../utils/exists'

export interface Locale {
  languageCode: string
  regionCode?: string
}

export function parseLocale(localeString: string): Locale {
  const [languageCode, regionCode] = localeString.split(/-|_/)

  return {
    languageCode: languageCode.toLowerCase(),
    regionCode: regionCode?.toUpperCase(),
  }
}

export function localeToString(locale: Locale): string {
  return [locale.languageCode, locale.regionCode].filter(exists).join('-')
}

export function forceLocaleStyle(localeString: string): string {
  return localeToString(parseLocale(localeString))
}
