<template>
  <div class="label-link">
    <AttensiLabel :label-id="label">
      {{ label }}
    </AttensiLabel>
    <a class="anchor" target="_blank" :href="url">{{ url }}</a>
    <p v-if="infoText" class="info-text small-text">
      {{ infoText }}
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { AttensiLabel } from '@attensi/materia'

export default defineComponent({
  components: {
    AttensiLabel,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    infoText: {
      type: String,
      required: false,
      default: undefined,
    },
  },
})
</script>

<style lang="scss" scoped>
.label-link {
  display: flex;
  flex-direction: column;
}

.anchor {
  margin: 8px 0;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  border-radius: 6px;
  padding: 8px 24px;
  line-height: 24px;
  background: $canvas;
  border: 1px solid $marble;
  color: $portal-blue-60;
}

.info-text {
  color: $concrete;
}
</style>
