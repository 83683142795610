import { getUsersMe } from '@/api/api'
import { useSWR } from '@attensi/swr'
import { nanoid } from 'nanoid'

export const useMyUserKey = nanoid()

export function useMyUser() {
  const swr = useSWR(useMyUserKey, getUsersMe)
  return { ...swr, user: swr.data }
}
