export const BACKEND_URL =
  process.env.VUE_APP_BACKEND_URL ?? 'https://api-staging.attensi.com/api/v1'

export const AUTH_URL = process.env.VUE_APP_AUTH_URL ?? 'https://auth-staging.attensi.com'

export const DEFAULT_LEADERBOARD_LIMIT = 12
export const ENV = process.env.VUE_APP_ENV ?? 'staging'
export const API_URL = process.env.VUE_APP_API_URL ?? 'https://api-staging.attensi.com'
export const API_TOKEN = process.env.VUE_APP_API_TOKEN ?? 'bfb744f8bb4dbec69e8340e4b0f4d2af2098cf7c27212c490c4dac210a43d3a9'

export const LOGIN_URL =
  process.env.VUE_APP_LOGIN_URL ?? 'https://login-staging.attensi.com'
