
import { defineComponent } from 'vue'
import { AttensiLabel } from '@attensi/materia'

export default defineComponent({
  components: {
    AttensiLabel,
  },
  props: {
    label: {
      type: String,
      required: true,
    },
    url: {
      type: String,
      required: true,
    },
    infoText: {
      type: String,
      required: false,
      default: undefined,
    },
  },
})
