<template>
  <div class="podium-item" :class="modifierClass">
    <img class="podium-item__crown" :src="crownIcon" />
    <div class="podium-item__avatar">
      <div class="podium-item__avatar-border">
        <img :src="avatar" />
      </div>
      <div class="podium-item__avatar-rank">{{ rank }}</div>
    </div>
    <div class="podium-item__name">{{ displayName }}</div>
    <div class="podium-item__score">{{ score }}</div>
  </div>
</template>

<script lang="ts">
import { ILeaderboardEntry } from '@/api/types'
import { getFormattedScore } from '@/helpers/getFormattedScore'
import { getUserAvatar } from '@/helpers/getUserAvatar'
import { getUserDisplayName } from '@/helpers/getUserDisplayName'
import { Company } from '@/types/Company'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {},

  props: {
    leaderboardEntry: {
      type: Object as PropType<ILeaderboardEntry>,
      required: true,
    },
    userDisplayFormat: {
      type: Object as PropType<Company['user_display_name_format']>,
      required: false,
      default: undefined,
    },
  },

  computed: {
    crownIcon() {
      switch (this.leaderboardEntry.rank) {
        case 1:
          return require('@/assets/gold-crown.svg')
        case 2:
          return require('@/assets/silver-crown.svg')
        case 3:
          return require('@/assets/bronze-crown.svg')
        default:
          return undefined
      }
    },
    modifierClass() {
      switch (this.leaderboardEntry.index + 1) {
        case 1:
          return 'podium-item--gold'
        case 2:
          return 'podium-item--silver'
        case 3:
          return 'podium-item--bronze'
        default:
          return undefined
      }
    },
    displayName() {
      return getUserDisplayName(
        this.leaderboardEntry.user,
        this.userDisplayFormat
      )
    },
    score() {
      return getFormattedScore(this.leaderboardEntry.value)
    },
    rank() {
      return this.leaderboardEntry.rank
    },
    avatar() {
      return getUserAvatar(this.leaderboardEntry.user)
    },
  },
})
</script>

<style lang="scss" scoped>
$color-dark-orange: #f76b1c;

.podium-item {
  grid-row-end: span 4;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  flex-shrink: 0;

  &__avatar {
    position: relative;
    height: 37.5%;
    aspect-ratio: 1;
    margin-bottom: 5%;
  }

  &__avatar-rank {
    display: flex;
    justify-content: center;
    align-items: flex-end;
    font-size: 2.4em;
    width: 1.4em;
    height: 1.4em;
    position: absolute;
    z-index: 2;
    border-radius: 100%;
    font-family: Oswald;
    font-weight: 600;
    top: 0;
    left: 0;
  }

  &__avatar-border {
    height: 100%;
    width: 100%;

    border: 0.5em solid white;
    background: white;
    border-radius: 100%;
    box-shadow: 0px 48.6634px 84.7104px rgba(166, 166, 166, 0.25);

    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;

    overflow: hidden;

    z-index: -1;

    img {
      position: absolute;
      width: 115%; // Avatars have inconsistent whitespace. Zoom in and crop to normalize
    }
  }

  &__name {
    font-family: Lato;
    font-weight: 900;
    text-align: center;

    font-size: 1.4em;
    width: 100%;

    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }

  &__score {
    color: $color-dark-orange;
    font-family: Oswald;
    font-size: 2.3em;
    font-weight: 700;
    line-height: 1;
    margin-top: 4%;
  }

  &--gold {
    grid-column-start: 2;
    transform: translateY(-15%);
  }

  &--gold &__crown {
    height: 4.5em;
    margin-left: 5%;
    margin-bottom: -2%;
  }

  &--gold &__score {
    margin-top: 2%;
  }

  &--gold &__avatar {
    height: 47%;
    margin-bottom: 8.5%;
  }

  &--gold &__avatar-border {
    box-shadow: 0px 4.69693px 11.2726px #ffe600,
      0px 48.6634px 112.895px rgba(255, 138, 0, 0.92);
  }

  &--gold &__avatar-rank {
    background: #ffd772;
    color: #cf9708;
    transform: translate(-8%, 15%);
  }

  &--silver {
    transform: translateY(2%) rotate(-4.12deg);
    grid-column-start: 1;
  }

  &--silver &__crown {
    height: 2.7em;
    margin-left: 5%;
    margin-bottom: 3%;
    transform: rotate(3deg);
  }

  &--silver &__avatar-rank {
    background: #ceffa7;
    color: #80bc00;
    transform: translate(-25%, 20%);
  }

  &--bronze {
    transform: translateY(0%) rotate(4.12deg);
    grid-column-start: 3;
  }

  &--bronze &__crown {
    height: 2.7em;
    margin-left: -5%;
    margin-bottom: 4%;
    transform: rotate(-3deg);
  }

  &--bronze &__avatar-rank {
    background: #8be3ff;
    color: #569ccf;
    transform: translate(-22.5%, 5%);
  }
}
</style>
