
import { AttensiDropdown } from '@attensi/materia'
import { defineComponent, PropType } from 'vue'
import { DropdownOption } from '@/types/DropdownOption'

export default defineComponent({
  components: {
    AttensiDropdown,
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<DropdownOption[]>,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: null,
    }
  },
})
