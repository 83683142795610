import { API_TOKEN, LOGIN_URL } from '@/constants'
import { redirect } from '@attensi/redirect'
import { getActiveSession, logoutAndGetNext } from '@attensi/auth'
import { getQueryParams } from '@/utils/url'

export const getAccessToken = async () => {
  const accessToken = (await getActiveSession()).accessToken
  return accessToken
}

export const getCompanyNameKey = async () => {
  const nameKey = (await getActiveSession()).companyNameKey
  return nameKey
}

export const getSubdomain = async () => {
  return (await getActiveSession()).subdomain
}


// TODO: remove this find usages
export const login = async () => {
  const session = await getActiveSession()
  redirect.toLogin({
    config: {
      api_token: API_TOKEN,
      subdomain: session?.subdomain,
    },
    login_url: LOGIN_URL,
    logout: true,
    pick_company: true,
  })
}

export const logout = async () => {
  await logoutAndGetNext()
}


// TODO: use getActiveSession from @attensi/auth
// TODO: Change in authFetch
export const createSession = async () => {
  const params = getQueryParams()
  return {
    accessToken: await getAccessToken(),
    apiToken: API_TOKEN,
    companyNameKey: await getCompanyNameKey(),
    rememberMe: Boolean(params.remember_me),
    locale: params.locale ?? 'en-US',
    subdomain: await getSubdomain(),
    transfer_key: true
  }
}
