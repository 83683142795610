
import { useMyUser } from '@/hooks/useMyUser'
import { useOnClickedOutside } from '@/hooks/useOnClickedOutside'
import { useToggle } from '@/hooks/useToggle'
import { defineComponent } from 'vue'
import { logout } from '@/auth'

export default defineComponent({
  setup() {
    const [isMenuVisible, toggleMenu] = useToggle()

    const containerRef = useOnClickedOutside(() => toggleMenu(false))

    return {
      ...useMyUser(),
      containerRef,
      toggleMenu,
      isMenuVisible,
    }
  },
  methods: {
    logout,
  },
})
