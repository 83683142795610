import { debounce } from 'lodash-es'
import { onMounted, onUnmounted, onUpdated, Ref, ref } from 'vue'

/*
  This is pretty weird so let me explain.
  The leaderboard is made for TVs, so it should look the same on 2K, 4K, 5K, whatever screens.
  Media queries are awkward because they require manually handling every possible resolution.
  Instead I chose to dynamically set the root element's font-size, this allows children to use em's and rem's as dynamic scaling properties.
*/
export function useDynamicallyScaledFontSize() {
  const elementRef = ref<HTMLDivElement>()

  const debouncedListener = debounce(
    () => measureAndUpdateFontSize(elementRef),
    100
  )

  onUpdated(() => {
    measureAndUpdateFontSize(elementRef)
  })

  onMounted(() => {
    measureAndUpdateFontSize(elementRef)
    window.addEventListener('resize', debouncedListener)
  })

  onUnmounted(() => {
    window.removeEventListener('resize', debouncedListener)
  })

  return elementRef
}

function measureElementHeight(element: HTMLDivElement) {
  return element.getBoundingClientRect().height
}

function getNumberOfGridRows(element: HTMLDivElement) {
  return window
    .getComputedStyle(element)
    .getPropertyValue('grid-template-rows')
    .split(/\s/).length
}

function measureAndUpdateFontSize(elementRef: Ref<HTMLDivElement | undefined>) {
  const element = elementRef.value

  if (element) {
    const gridHeight = measureElementHeight(element)
    const gridRows = getNumberOfGridRows(element)

    const baseMultiplier = 8 // Original design is based on 8 rows
    const multiplier = Math.max(baseMultiplier, gridRows) // Adapt scaling to additional rows

    const fontSize = ((gridHeight / 50) * baseMultiplier) / multiplier
    window.document.documentElement.style.fontSize = `${fontSize}px`
  }
}
