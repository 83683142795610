import { Company } from '@/types/Company'

const TEXT_FALLBACK = 'Unknown'

type User = {
  first_name?: string | null
  last_name?: string | null
  username?: string | null
}

export function getUserDisplayName(
  user: User | undefined,
  format: Company['user_display_name_format'] = 'firstname_and_lastname'
): string {
  if (user === undefined) return ''

  const { username, first_name, last_name } = user

  if (username === 'Anonymous') return username

  switch (format) {
    case 'username':
      return username || TEXT_FALLBACK
    case 'firstname':
      return first_name || TEXT_FALLBACK
    case 'firstname_and_lastname':
    default: {
      const fullName = [first_name, last_name].filter(Boolean).join(' ')
      return fullName || TEXT_FALLBACK
    }
  }
}
