import { DEFAULT_LEADERBOARD_LIMIT } from '@/constants'
import { addGroupType } from '@/helpers/addGroupType'
import { unravelDepartments } from '@/helpers/unravelDepartments'
import { Company } from '@/types/Company'
import { get, getWithCompanyNameKey, post, publicGet } from '@/utils/http'
import { withParams } from '@/utils/url'
import { PUser } from '@attensi/portal-models'
import {
  GroupType,
  IGroup,
  ILeaderboard,
  IProduct,
  IScenario,
  IScenarioCategory,
} from './types'

export async function getUsersMe() {
  return get<PUser>('/users/me')
}

export async function getCurrentCompany() {
  return get<Company>('/companies/current')
}

export function getProductsForUser(userId: number) {
  const url = `/users/${userId}/products`
  return get<IProduct[]>(url)
}

export function getScenarioCategoriesForProduct(
  userId: number,
  productId: number
) {
  const url = `/users/${userId}/products/${productId}/scenario_categories`
  return get<IScenarioCategory[]>(url)
}

export function getScenarioById(userId: number, scenarioId: number) {
  const url = `/users/${userId}/scenarios/${scenarioId}/status`
  return get<IScenario>(url)
}

export async function getAllGroups(): Promise<IGroup[]> {
  const [leaderboardGroups, departments, positions, customGroups] =
    await Promise.all([
      get(`/companies/current/leaderboard_groups`),
      get(`/companies/current/departments`),
      get(`/companies/current/positions`),
      get(`/companies/current/default_groups`),
    ])

  return [
    ...leaderboardGroups,
    ...unravelDepartments(departments),
    ...positions,
    ...addGroupType(customGroups, 'custom'),
  ]
}

export async function getLeaderboardToken(
  product_id: number,
  group_id: number,
  scenario_id?: number
): Promise<string> {
  const params = { product_id, group_id, scenario_id }
  const url = withParams('/leaderboards/token', params)
  return (await getWithCompanyNameKey(url)).token
}

export async function getLeaderboardFromToken(
  token: string,
  limit: number = DEFAULT_LEADERBOARD_LIMIT
): Promise<ILeaderboard> {
  limit = Math.min(30, limit)
  const url = withParams(`/leaderboards/${token}`, { limit })
  return publicGet(url)
}

export async function addUserToLeaderboardGroup(
  userId: number,
  groupId: number,
  token: string
) {
  const url = withParams(`/users/${userId}/groups/${groupId}/leaderboard_add`, {
    token,
  })
  return post(url)
}

export async function createGroup(name: string, group_type: GroupType) {
  const group = {
    name,
    group_type,
  }
  return post('/groups', group)
}
