import { createApp } from 'vue'
import App from './App.vue'
import { i18n } from './i18n'
import router from './router'
import { authInit } from './helpers/useAuth'

authInit()


const startApp = async () => {
  const app = createApp(App)
  app.use(i18n)
  app.use(router)
  app.mount('#app')
}

startApp().catch((error) => {
  console.error(error)
})