import { useSWR } from '@attensi/swr'
import { nanoid } from 'nanoid'
import { getCurrentCompany } from '../api/api'

export const uniqueKey = nanoid()

export function useCurrentCompany() {
  const key = [uniqueKey]
  const swr = useSWR(key, getCurrentCompany)
  return {
    company: swr.data,
    error: swr.error,
  }
}
