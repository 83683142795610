import { ILeaderboard } from '@/api/types'
import { useSWR } from '@attensi/swr'
import { nanoid } from 'nanoid'
import { Ref } from 'vue'
import { getLeaderboardFromToken } from '../api/api'

type SWRParam<T> = T | Ref<T>

export const useLiveLeaderboardKey = nanoid()

export function useLiveLeaderboard(
  tokenParam: SWRParam<string>,
  limitParam?: SWRParam<number | undefined>
) {
  const key = [tokenParam, limitParam, useLiveLeaderboardKey]
  const swr = useSWR(key, (token, limit) =>
    getLeaderboardFromToken(token, limit).then(addIndex)
  )
  return {
    leaderboard: swr.data,
    error: swr.error,
    mutate: swr.mutate,
  }
}

function addIndex(leaderboard: ILeaderboard): ILeaderboard {
  const entries = leaderboard.entries.map((entry, index) => ({
    ...entry,
    index,
  }))

  return { ...leaderboard, entries }
}
