import { initAppLauncher } from '@attensi/app-launcher';
import { createSession } from '@/auth';
import { ENV, AUTH_URL, API_URL } from '../constants';

export async function initializeAppLauncher() {
  try {

    const { accessToken, apiToken, companyNameKey, subdomain } = await createSession();

    initAppLauncher({
      env: ENV,
      authUrl: AUTH_URL,
      apiUrl: API_URL,
      accessToken,
      apiToken,
      subdomain: subdomain,
      companyNameKey,
    });
  } catch (error) {
    console.error('Error initializing App Launcher:', error);
  }
}