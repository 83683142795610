
import { ILeaderboardEntry } from '@/api/types'
import { getFormattedScore } from '@/helpers/getFormattedScore'
import { getUserAvatar } from '@/helpers/getUserAvatar'
import { getUserDisplayName } from '@/helpers/getUserDisplayName'
import { Company } from '@/types/Company'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {},

  props: {
    leaderboardEntry: {
      type: Object as PropType<ILeaderboardEntry>,
      required: true,
    },
    userDisplayFormat: {
      type: Object as PropType<Company['user_display_name_format']>,
      required: false,
      default: undefined,
    },
  },

  computed: {
    displayName() {
      return getUserDisplayName(
        this.leaderboardEntry.user,
        this.userDisplayFormat
      )
    },
    score() {
      return getFormattedScore(this.leaderboardEntry.value)
    },
    avatar() {
      return getUserAvatar(this.leaderboardEntry.user)
    },
  },
})
