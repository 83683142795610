interface Department {
  children: Department[]
  name: string
  id: number
  group_type: string
}

export function unravelDepartments(
  departments: Department[] | {}
): Department[] {
  if (!Array.isArray(departments)) return []

  return departments
    .flatMap(unravelDepartment)
    .map((dep) => ({ ...dep, group_type: 'department' }))
}

function unravelDepartment(department: Department): Department[] {
  return [department, ...unravelDepartments(department.children)]
}
