import { BACKEND_URL } from '@/constants'
import { authFetch } from '@/api/authFetch'
import { getCompanyNameKey } from '@/auth'

export async function get<T = any>(
  path: string,
  init?: RequestInit
): Promise<T> {
  return (await apiRequest('GET', path, init)).json()
}

export async function publicGet<T = any>(
  path: string,
  init?: RequestInit
): Promise<T> {
  return (await apiRequestPublic('GET', path, init)).json()
}

export async function getWithCompanyNameKey(path: string) {
  const companyNameKey = await getCompanyNameKey()

  return get(path, {
    headers: { 'company-name-key': companyNameKey },
  })
}

export function post(path: string, data?: any) {
  const init: RequestInit = {
    body: JSON.stringify(data),
    headers: {
      'content-type': 'application/json',
    },
  }
  return apiRequest('POST', path, init)
}

function apiRequest(method: string, path: string, init?: RequestInit) {
  if (!path.startsWith('/')) path = '/' + path

  const url = BACKEND_URL + path
  return authFetch(url, { ...init, method })
}

async function apiRequestPublic(
  method: string,
  path: string,
  init?: RequestInit
) {
  if (!path.startsWith('/')) path = '/' + path

  const url = BACKEND_URL + path
  const res = await fetch(url, { ...init, method })

  if (!res.ok) {
    console.error('Request failed', res)
    throw new Error('Request failed. StatusCode: ' + res.status)
  }

  return res
}
