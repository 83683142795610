import { RouteRecordRaw } from 'vue-router'
import FacilitatorTools from '@/views/FacilitatorTools.vue'
import PublicLeaderboard from '@/views/PublicLeaderboard.vue'
import InviteFlow from '@/views/InviteFlow.vue'
import TestPage from '@/views/TestPage.vue'

export const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    component: FacilitatorTools,
  },
  {
    path: '/leaderboard/:token',
    component: PublicLeaderboard,
  },
  {
    path: '/invite/:token',
    component: InviteFlow,
  },
  {
    path: '/test',
    component: TestPage,
  },
]
