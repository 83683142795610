
import DropdownWrapper from '@/components/DropdownWrapper.vue'
import TheHeader from '@/components/TheHeader.vue'
import {
  CREATE_NEW_LEADERBOARD_GROUP,
  SELECT_EVERYONE,
  generateLeaderboardUrls,
  LeaderboardUrls,
} from '@/helpers/generateLeaderboardUrls'
import {
  useGroupOptions,
  useProductOptions,
  useScenarioOptions,
} from '@/hooks/useDropdownOptions'
import { useMyUser } from '@/hooks/useMyUser'
import { anyError } from '@/utils/anyError'
import { DropdownOption } from '@/types/DropdownOption'
import {
  AttensiButton,
  AttensiInput,
  AttensiLoadingSpinner,
} from '@attensi/materia'
import { computed, defineComponent, ref } from 'vue'
import LabelLink from '@/components/LabelLink.vue'
import { DEFAULT_LEADERBOARD_LIMIT } from '@/constants'
import { GroupType } from '@/api/types'
import { useCurrentCompany } from '@/hooks/useCurrentCompany'
import { useI18n } from 'vue-i18n'
import { initializeAppLauncher } from '../helpers/appLauncher'

export default defineComponent({
  components: {
    AttensiButton,
    AttensiInput,
    TheHeader,
    AttensiLoadingSpinner,
    DropdownWrapper,
    LabelLink,
  },
  setup() {
    initializeAppLauncher()
    window.document.title = 'Create leaderboard'

    const { user, error: e1 } = useMyUser()
    const { company, error: e5 } = useCurrentCompany()
    const { t } = useI18n()

    const title = ref('')
    const product = ref<DropdownOption>()
    const group = ref<DropdownOption>()
    const scenario = ref<DropdownOption>()
    const limit = ref(DEFAULT_LEADERBOARD_LIMIT)
    const redirectUrl = ref('')

    const userId = computed(() => user.value?.id)
    const productId = computed(() => product.value?.product.id)

    const { productOptions, error: e2 } = useProductOptions(userId)
    const { groupOptions, error: e3 } = useGroupOptions(userId)
    const { scenarioOptions, error: e4 } = useScenarioOptions(userId, productId)

    const groupOptionsWithCreate = computed(() => {
      if (groupOptions.value === undefined) return

      const newGroupOption: DropdownOption = {
        id: -1,
        title: t('facilitator_tools.new_group'),
        description: t('facilitator_tools.new_group_helper_text'),
        group: {
          id: CREATE_NEW_LEADERBOARD_GROUP,
          group_type: GroupType.LEADERBOARD,
        },
      }
      const everyOneGroupOption: DropdownOption = {
        id: -2,
        title: t('facilitator_tools.everyone'),
        description: t('facilitator_tools.everyone_helper_text'),
        group: {
          id: SELECT_EVERYONE,
          group_type: undefined,
        },
      }
      return [everyOneGroupOption, newGroupOption, ...groupOptions.value]
    })

    return {
      user,
      company,
      title,
      product,
      group,
      scenario,
      limit,
      redirectUrl,
      productOptions,
      groupOptionsWithCreate,
      scenarioOptions,
      error: anyError(e1, e2, e3, e4, e5),
    }
  },
  data() {
    return {
      isGeneratingLeaderboard: false,
      urls: undefined as LeaderboardUrls | undefined,
    }
  },
  methods: {
    async submit() {
      this.isGeneratingLeaderboard = true

      try {
        this.urls = await generateLeaderboardUrls({
          user: this.user!,
          company: this.company!,
          productId: this.product?.product.id,
          groupId: this.group?.group.id,
          groupType: this.group?.group.group_type,
          scenarioId: this.scenario?.scenario.scenario_id,
          redirectUrl: this.redirectUrl,
          title: this.title,
          limit: this.limit,
        })
      } catch (e) {
        this.error = e
      } finally {
        this.isGeneratingLeaderboard = false
      }
    },
  },
})
