import {
  getAllGroups,
  getProductsForUser,
  getScenarioById,
  getScenarioCategoriesForProduct,
} from '@/api/api'
import { Language, LanguageTexts } from '@/api/types'
import { DropdownOption } from '@/types/DropdownOption'
import { capitalize, sortBy } from 'lodash-es'
import { isProductScorable } from './isProductScorable'

export async function fetchProductOptions(
  userId: number
): Promise<DropdownOption[]> {
  const productOptions = (await getProductsForUser(userId))
    .filter((product) => product.title)
    .filter((product) => isProductScorable(product.product_type))
    .map((product) => ({
      id: product.id,
      search: product.title!,
      title: product.title,
      description: product.description,
      product,
    }))

  return sortBy(productOptions, (option) => option.title)
}

export async function fetchGroupOptions(
  userId: number
): Promise<DropdownOption[]> {
  return (await getAllGroups()).map((group) => ({
    id: group.id,
    title: group.name,
    search: group.name,
    description: capitalize(group.group_type),
    group,
  }))
}

export async function fetchScenarioOptions(
  userId: number,
  productId: number
): Promise<DropdownOption[]> {
  const categories = await getScenarioCategoriesForProduct(userId, productId)
  const pendingScenarios = categories.flatMap((category) => {
    return category.scenario_ids.map((scenarioId) =>
      getScenarioById(userId, scenarioId)
    )
  })
  const scenarios = await Promise.all(pendingScenarios)
  return scenarios
    .map((scenario, index) => {
      return {
        id: index,
        search: getLanguageString(scenario.title, 'en-US'),
        title: getLanguageString(scenario.title, 'en-US'),
        description: getLanguageString(scenario.description, 'en-US'),
        scenario,
      }
    })
    .filter((scenario) => scenario.title) as DropdownOption[]
}

function getLanguageString(thing: LanguageTexts | null, language: Language) {
  if (!thing) return ''

  const text = thing[language]
  if (text === undefined) return Object.values(thing)[0]

  return text
}
