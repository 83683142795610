import { createGroup, getLeaderboardToken } from '@/api/api'
import { GroupType } from '@/api/types'
import { Company } from '@/types/Company'
import { withParams } from '@/utils/url'
import { PUser } from '@attensi/portal-models'
import { getUserDisplayName } from './getUserDisplayName'

interface GenerateLeaderboardUrlsData {
  productId: number
  groupId: number
  groupType: string
  scenarioId: number | undefined
  limit: number
  redirectUrl: string
  title: string
  user: PUser
  company: Company
}

export const CREATE_NEW_LEADERBOARD_GROUP = -100
export const SELECT_EVERYONE = -101

export interface LeaderboardUrls {
  leaderboardUrl: string
  inviteUrl?: string
}

export async function generateLeaderboardUrls(
  data: GenerateLeaderboardUrlsData
): Promise<LeaderboardUrls> {
  const { origin } = window.location
  const { productId, scenarioId } = data

  const groupId = await getGroupId(data)
  const token = await getLeaderboardToken(productId, groupId, scenarioId)

  const leaderboardUrl = getLeaderboardUrl(origin, data, token)
  const inviteUrl = getInviteUrl(origin, { ...data, groupId }, token)

  return { leaderboardUrl, inviteUrl }
}

async function getGroupId({
  groupId,
  title,
  user,
}: GenerateLeaderboardUrlsData) {
  if (groupId === CREATE_NEW_LEADERBOARD_GROUP) {
    return createLeaderboardGroup(title, user)
  }
  if (groupId === SELECT_EVERYONE) {
    return undefined
  }
  else {
    return groupId
  }
}

async function createLeaderboardGroup(title: string, user: PUser) {
  const groupName = title || generateLeaderboardGroupName(user)
  const res = await createGroup(groupName, GroupType.LEADERBOARD)
  return (await res.json()).id
}

function generateLeaderboardGroupName(user: PUser) {
  const userDisplayName = getUserDisplayName(user)
  const timeStamp = new Intl.DateTimeFormat([], {
    dateStyle: 'medium',
    timeStyle: 'medium',
  }).format(new Date())
  return `Created by ${userDisplayName} - ${timeStamp}`
}

function getLeaderboardUrl(
  origin: string,
  { limit, title, company }: GenerateLeaderboardUrlsData,
  token: string
) {
  const userDisplayFormat = company.user_display_name_format
  return withParams(`${origin}/leaderboard/${token}`, {
    limit,
    title,
    userDisplayFormat,
  })
}

function getInviteUrl(
  origin: string,
  { redirectUrl, groupType, groupId }: GenerateLeaderboardUrlsData,
  token: string
) {
  if (groupType !== GroupType.LEADERBOARD) return undefined

  return withParams(`${origin}/invite/${token}`, {
    redirect_url: redirectUrl,
    group_id: groupId,
  })
}
