<template>
  <router-view />
</template>

<script lang="ts">
import '@attensi/materia/dist/style.css'
import '@attensi/materia/dist/opt-in-styles.css'
import { defineComponent } from 'vue'

export default defineComponent({})
</script>

<style lang="scss">
@import './globals.scss';
</style>
