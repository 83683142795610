<template>
  <AttensiDropdown v-model="selectedOption" searching :options="options ?? []">
    <template #input-content>
      {{ selectedOption?.title || placeholder }}
    </template>
    <template #no-search-results>
      {{ $t('facilitator_tools.dropdown_no_results') }}
    </template>
    <template #option="{ data }">
      <div class="option">
        <div class="title">
          {{ data.title }}
        </div>
        <div v-if="data.description" class="description">
          {{ data.description.substring(0, 150) }}
        </div>
      </div>
    </template>
  </AttensiDropdown>
</template>

<script lang="ts">
import { AttensiDropdown } from '@attensi/materia'
import { defineComponent, PropType } from 'vue'
import { DropdownOption } from '@/types/DropdownOption'

export default defineComponent({
  components: {
    AttensiDropdown,
  },
  props: {
    placeholder: {
      type: String,
      required: true,
    },
    options: {
      type: Array as PropType<DropdownOption[]>,
      required: true,
    },
  },
  data() {
    return {
      selectedOption: null,
    }
  },
})
</script>

<style lang="scss" scoped>
.option {
  padding: 5px 0;
}
.title {
  font-weight: 600;
}
.description {
  margin-top: 5px;
  font-size: 0.9rem;
}
::v-deep .attensi-dropdown .dropdown-option-list {
  max-height: 500px;
}
</style>
