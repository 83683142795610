import { onUnmounted, ref } from 'vue'

export function useOnClickedOutside(onClickedOutside: (e: MouseEvent) => void) {
  const containerRef = ref<HTMLDivElement>()

  const clickListener = (e: MouseEvent) => {
    const clickedInContainer = containerRef.value?.contains(e.target as Node)
    if (!clickedInContainer) {
      onClickedOutside(e)
    }
  }

  document.addEventListener('click', clickListener)

  onUnmounted(() => {
    document.removeEventListener('click', clickListener)
  })

  return containerRef
}
