import { reverse } from 'lodash-es'

export function getFormattedScore(score: number): string {
  const formatted: string[] = []
  const reversed = reverse(score.toString().split(''))
  reversed.forEach((char, i) => {
    const segment = Math.floor(i / 3)
    formatted[segment] = char + (formatted[segment] ?? '')
  })
  return reverse(formatted).join(' ')
}
