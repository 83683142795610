
import { addUserToLeaderboardGroup, getUsersMe } from '@/api/api'
import { numberOrUndefined } from '@/utils/numberOrUndefined'
import { defineComponent, onMounted, Ref, ref } from 'vue'
import { useRoute } from 'vue-router'
import { AttensiLoadingSpinner } from '@attensi/materia'

export default defineComponent({
  components: { AttensiLoadingSpinner },
  setup() {
    const error = addMeOnMount()
    return { error }
  },
})

function addMeOnMount(): Ref<Error | undefined> {
  const { params, query } = useRoute()
  const token = params.token as string
  const redirectUrl = query.redirect_url as string | undefined
  const groupId = numberOrUndefined(query.group_id as string | undefined)

  const error = ref<Error>()

  onMounted(async () => {
    try {
      await addMeToGroup(token, groupId!)
      window.location.href = getRedirectUrl(redirectUrl, token)
    } catch (e: any) {
      console.error(e)
      error.value = e
    }
  })

  return error
}

async function addMeToGroup(token: string, groupId: number) {
  const { id: userId } = await getUsersMe()
  const res = await addUserToLeaderboardGroup(userId, groupId, token)
  const { success, message } = await res.json()

  if (!success) {
    throw Error(message || 'Failed to invite user')
  }
}

function getRedirectUrl(redirectUrl: string | undefined, token: string) {
  return validateRedirectUrl(redirectUrl) || `/leaderboard/${token}`
}

function validateRedirectUrl(
  redirectUrl: string | undefined
): string | undefined {
  try {
    const url = new URL(redirectUrl!)
    const isAttensiHost = /\.attensi.com$/
    if (url.host.match(isAttensiHost)) {
      return redirectUrl
    }
  } catch (e) {
    console.warn(e)
  }
}
