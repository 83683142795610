import { Locale, localeToString, parseLocale } from './types/Locale'

export function pickUserLocale(
  supportedLocales: string[],
  fallbackLocale: string
): string {
  // Browser locales are ordered by user preference
  const browserLocales = window.navigator.languages ?? [
    window.navigator.language,
  ]

  const locale = pickMatchingLocale(
    browserLocales.map(parseLocale),
    supportedLocales.map(parseLocale)
  )

  if (locale === undefined) {
    return fallbackLocale
  }

  return localeToString(locale)
}

/*
  Keeps the ordering of browserLocales
  Returns values from supportedLocales, as browserLocales doesn't always contain regionCode
*/
function pickMatchingLocale(
  browserLocales: Locale[],
  supportedLocales: Locale[]
): Locale | undefined {
  for (const left of browserLocales) {
    for (const right of supportedLocales) {
      if (isExactlyEqual(left, right)) return right
    }
    for (const right of supportedLocales) {
      if (isPartiallyEqual(left, right)) return right
    }
  }
}

function isExactlyEqual(left: Locale, right: Locale): boolean {
  return (
    left.languageCode === right.languageCode &&
    left.regionCode === right.regionCode
  )
}

function isPartiallyEqual(left: Locale, right: Locale): boolean {
  return left.languageCode === right.languageCode
}
