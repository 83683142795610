
import { defineComponent, ref, onMounted } from 'vue'
import Avatar from '@/components/Avatar.vue'
import { useCurrentCompany } from '@/hooks/useCurrentCompany'
import {
  createAppLauncherButton,
  isQueryParamEnabled,
} from '@attensi/app-launcher'

export default defineComponent({
  components: { Avatar },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const appLauncherBtn = ref<HTMLDivElement | null>(null)
    const { company } = useCurrentCompany()

    onMounted(() => {
      if (appLauncherBtn.value) {
        createAppLauncherButton(appLauncherBtn.value)
      }
    })

    return { company, appLauncherBtn, isQueryParamEnabled }
  },
})
