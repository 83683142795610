import { initializeAuth, getSubdomainFromPath } from '@attensi/auth'
import { API_TOKEN, LOGIN_URL, AUTH_URL, API_URL } from '@/constants'


export const authInit = () => {
  return initializeAuth({
    authUrl: AUTH_URL,
    backendApiToken: API_TOKEN,
    backendUrl: API_URL,
    loginUrl: LOGIN_URL,
    subdomain: getSubdomainFromPath(),
  })
}

