<template>
  <div>hei</div>
</template>

<script>
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},
  setup(props, context) {
    console.log(context)
  },
  methods: {},
})
</script>
