<template>
  <div class="highscore">
    <div class="highscore__rank">{{ leaderboardEntry.rank }}</div>
    <img class="highscore__avatar" :src="avatar" />
    <div>
      <div class="highscore__name">{{ displayName }}</div>
      <div class="highscore__score">{{ score }}</div>
    </div>
  </div>
</template>

<script lang="ts">
import { ILeaderboardEntry } from '@/api/types'
import { getFormattedScore } from '@/helpers/getFormattedScore'
import { getUserAvatar } from '@/helpers/getUserAvatar'
import { getUserDisplayName } from '@/helpers/getUserDisplayName'
import { Company } from '@/types/Company'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {},

  props: {
    leaderboardEntry: {
      type: Object as PropType<ILeaderboardEntry>,
      required: true,
    },
    userDisplayFormat: {
      type: Object as PropType<Company['user_display_name_format']>,
      required: false,
      default: undefined,
    },
  },

  computed: {
    displayName() {
      return getUserDisplayName(
        this.leaderboardEntry.user,
        this.userDisplayFormat
      )
    },
    score() {
      return getFormattedScore(this.leaderboardEntry.value)
    },
    avatar() {
      return getUserAvatar(this.leaderboardEntry.user)
    },
  },
})
</script>

<style lang="scss" scoped>
$color-grey: #c6c6c6;
$color-dark-orange: #f76b1c;

.highscore {
  // justify-content: center;
  margin-left: 1em;
  align-items: center;
  display: flex;

  &__rank {
    font-family: Oswald;
    font-size: 1.75em;
    font-weight: 600;
    width: 1em;
    color: $color-grey;
  }

  &__avatar {
    width: 4em;
    height: 4em;
  }

  &__name {
    color: $coal;
    font-family: Lato;
    font-size: 1.05em;
    font-weight: 700;
  }

  &__score {
    color: $color-dark-orange;
    font-family: Oswald;
    font-size: 1em;
    font-weight: 700;
  }
}
</style>
