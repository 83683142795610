import { merge } from 'lodash-es'
import { I18N_URL } from '../constants'
import { i18n } from '../i18n'
import { ProjectManifest } from '../types/ProjectManifest'

const isLocaleFetched = new Map<string, boolean>()

export function loadLocales(manifest: ProjectManifest, locales: string[]) {
  return Promise.all(locales.map((locale) => loadLocale(manifest, locale)))
}

export async function loadLocale(
  manifest: ProjectManifest,
  locale: string
): Promise<boolean> {
  if (isLocaleFetched.has(locale)) return true
  isLocaleFetched.set(locale, true)

  const fileName = manifest.locales[locale]?.fileName
  if (fileName === undefined) {
    console.warn('Cannot load', locale, '. It is not present in the manifest')
    return false
  }

  const messages = await fetchLocale(fileName)
  const existingMessages = (i18n.global.messages as any)[locale] ?? {}
  const mergedMessages = merge({}, existingMessages, messages)

  i18n.global.setLocaleMessage(locale, mergedMessages)
  return messages !== undefined
}

function fetchLocale(fileName: string) {
  const url = `${I18N_URL}/${fileName}`

  const onError = () => {
    console.error('Failed to load locale.', { fileName, url })
  }

  return fetch(url)
    .then((res) => {
      if (!res.ok) return onError()
      return res.json()
    })
    .catch(onError)
}
