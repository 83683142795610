import {
  fetchGroupOptions,
  fetchProductOptions,
  fetchScenarioOptions,
} from '@/helpers/fetchDropdownOptions'
import { useSWR } from '@attensi/swr'
import { nanoid } from 'nanoid'
import { Ref } from 'vue'

export const useProductOptionsKey = nanoid()
export const useGroupOptionsKey = nanoid()
export const useScenarioOptionsKey = nanoid()

export function useProductOptions(userIdRef: Ref<number | undefined>) {
  const key = [useProductOptionsKey, userIdRef]
  const swr = useSWR(key, (_, userId) => {
    if (userId === undefined) return
    return fetchProductOptions(userId)
  })
  return { productOptions: swr.data, ...swr }
}

export function useGroupOptions(userIdRef: Ref<number | undefined>) {
  const key = [useGroupOptionsKey, userIdRef]
  const swr = useSWR(key, (_, userId) => {
    if (userId === undefined) return
    return fetchGroupOptions(userId)
  })
  return { groupOptions: swr.data, ...swr }
}

export function useScenarioOptions(
  userIdRef: Ref<number | undefined>,
  productIdRef: Ref<number | undefined>
) {
  const key = [useScenarioOptionsKey, userIdRef, productIdRef]
  const swr = useSWR(key, (_, userId, productId) => {
    if (userId === undefined || productId === undefined) return
    return fetchScenarioOptions(userId, productId)
  })
  return { scenarioOptions: swr.data, ...swr }
}
