
import { ILeaderboardEntry } from '@/api/types'
import { getFormattedScore } from '@/helpers/getFormattedScore'
import { getUserAvatar } from '@/helpers/getUserAvatar'
import { getUserDisplayName } from '@/helpers/getUserDisplayName'
import { Company } from '@/types/Company'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {},

  props: {
    leaderboardEntry: {
      type: Object as PropType<ILeaderboardEntry>,
      required: true,
    },
    userDisplayFormat: {
      type: Object as PropType<Company['user_display_name_format']>,
      required: false,
      default: undefined,
    },
  },

  computed: {
    crownIcon() {
      switch (this.leaderboardEntry.rank) {
        case 1:
          return require('@/assets/gold-crown.svg')
        case 2:
          return require('@/assets/silver-crown.svg')
        case 3:
          return require('@/assets/bronze-crown.svg')
        default:
          return undefined
      }
    },
    modifierClass() {
      switch (this.leaderboardEntry.index + 1) {
        case 1:
          return 'podium-item--gold'
        case 2:
          return 'podium-item--silver'
        case 3:
          return 'podium-item--bronze'
        default:
          return undefined
      }
    },
    displayName() {
      return getUserDisplayName(
        this.leaderboardEntry.user,
        this.userDisplayFormat
      )
    },
    score() {
      return getFormattedScore(this.leaderboardEntry.value)
    },
    rank() {
      return this.leaderboardEntry.rank
    },
    avatar() {
      return getUserAvatar(this.leaderboardEntry.user)
    },
  },
})
