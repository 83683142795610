<template>
  <div class="border">
    <div class="header">
      <div class="header-mini">
        <div
          v-if="isQueryParamEnabled"
          ref="appLauncherBtn"
          class="header-mini__app-launcher"
        ></div>
        <img :src="company?.logo" />
        <div class="header-mini__title">Live Leaderboards</div>
      </div>
      <Avatar />
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref, onMounted } from 'vue'
import Avatar from '@/components/Avatar.vue'
import { useCurrentCompany } from '@/hooks/useCurrentCompany'
import {
  createAppLauncherButton,
  isQueryParamEnabled,
} from '@attensi/app-launcher'

export default defineComponent({
  components: { Avatar },
  props: {
    title: {
      type: String,
      required: true,
    },
  },
  setup() {
    const appLauncherBtn = ref<HTMLDivElement | null>(null)
    const { company } = useCurrentCompany()

    onMounted(() => {
      if (appLauncherBtn.value) {
        createAppLauncherButton(appLauncherBtn.value)
      }
    })

    return { company, appLauncherBtn, isQueryParamEnabled }
  },
})
</script>

<style scoped lang="scss">
.border {
  border-bottom: $marble 1px solid;
  position: relative;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 100%;
  gap: 10px;
  position: relative;
  height: 91px;
  padding: 0px 20px;
}

.header-mini {
  display: flex;
  align-items: center;

  &__app-launcher {
    margin-right: 30px;
  }

  img {
    max-width: 200px;
    object-fit: contain;
    display: block;
    height: 90px;

    @media (max-width: 1300px) {
      display: none;
    }
  }

  &__title {
    font-size: 20px;
    font-weight: 700;
    margin-left: 15px;
    padding-left: 25px;
    border-left: solid 1px #e3e3e3;
    @media (max-width: 1300px) {
      border-left: none;
      text-align: center;
      margin: 0px;
      padding: 0px;
    }
  }
}

.title {
  font-size: 25px;
  text-align: center;

  @media (min-width: 1000px) {
    width: 100%;
  }

  @media (min-width: 800px) {
    width: 100%;
  }

  @media (max-width: 800px) {
    width: 100%;
  }

  @media (max-width: 400px) {
    font-size: 15px;
    text-align: right;
  }
}
</style>
