<template>
  <div class="leaderboard-container">
    <LoopedTimer :timer="timer" />
    <div
      ref="parentRef"
      class="leaderboard-grid"
      :class="{
        'leaderboard-grid--with-title': title,
        'leaderboard-grid--loading': isLoading,
      }"
    >
      <div v-if="title" class="title">{{ title }}</div>
      <LeaderboardPodiumItem
        v-for="entry in podiumEntries"
        :key="entry.index"
        :leaderboard-entry="entry"
        :user-display-format="userDisplayFormat"
      />

      <LeaderboardPlayerItem
        v-for="entry in playerEntries"
        :key="entry.index"
        :leaderboard-entry="entry"
        :user-display-format="userDisplayFormat"
      />
    </div>
  </div>
</template>

<script lang="ts">
import { ILeaderboard, ILeaderboardEntry } from '@/api/types'
import LeaderboardPlayerItem from '@/components/LeaderboardPlayerItem.vue'
import LeaderboardPodiumItem from '@/components/LeaderboardPodiumItem.vue'
import LoopedTimer from '@/components/LoopedTimer.vue'
import { useDynamicallyScaledFontSize } from '@/hooks/useDynamicallyScaledFontSize'
import { Company } from '@/types/Company'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {
    LeaderboardPodiumItem,
    LeaderboardPlayerItem,
    LoopedTimer,
  },

  props: {
    leaderboard: {
      type: Object as PropType<ILeaderboard>,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    userDisplayFormat: {
      type: Object as PropType<Company['user_display_name_format']>,
      required: false,
      default: undefined,
    },
    timer: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    return { parentRef: useDynamicallyScaledFontSize() }
  },

  computed: {
    podiumEntries(): ILeaderboardEntry[] {
      return this.leaderboard.entries.filter((e) => e.index < 3)
    },

    playerEntries(): ILeaderboardEntry[] {
      return this.leaderboard.entries.filter((e) => e.index >= 3)
    },
  },
})
</script>

<style lang="scss" scoped>
.leaderboard-container {
  height: 100vh;
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  overflow: hidden;

  // padding-block-start: 1em;
  // padding-block-end: 1em;
}

.leaderboard-grid {
  // padding-top: 2em;
  aspect-ratio: 1;

  max-width: 100%;
  height: 100%;

  display: grid;
  grid-template-columns: repeat(
    3,
    minmax(0, 1fr)
  ); // minmax to prevent grid blowout https://css-tricks.com/equal-width-columns-in-css-grid-are-kinda-weird/
  grid-auto-rows: minmax(0, 7.5em);
  grid-auto-flow: dense;
  // gap: 1em;

  padding-block-start: 3em;

  opacity: 1;
  transition: opacity 0.2s ease;

  &--with-title {
    grid-template-rows: min-content;
    padding-block-start: 0;
  }

  &--loading {
    opacity: 0;
  }
}

.title {
  grid-column: span 3;

  text-align: center;
  font-family: Lato;
  font-size: 2.3em;
  font-weight: 900;
  color: $coal;

  display: flex;
  justify-content: center;
  align-items: center;

  padding-block-start: 0.5em;
  padding-block-end: 1.25em;
}
</style>
