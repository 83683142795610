<template>
  <div ref="containerRef" class="avatar-container">
    <button v-if="user" class="avatar" @click="toggleMenu">
      <img class="left-block" :src="user.image" alt="Your avatar" />
      <div class="right-block">
        <p class="subtitle">{{ user.first_name }} {{ user.last_name }}</p>
        <p class="small-text job-title">{{ user.position }}</p>
      </div>
    </button>
    <div v-if="isMenuVisible" class="dropdown">
      <button class="dropdown__logout" @click="logout">
        <img src="@/assets/log-out-icon.svg" alt="Log out" />
        {{ $t('facilitator_tools.log_out') }}
      </button>
    </div>
  </div>
</template>

<script lang="ts">
import { useMyUser } from '@/hooks/useMyUser'
import { useOnClickedOutside } from '@/hooks/useOnClickedOutside'
import { useToggle } from '@/hooks/useToggle'
import { defineComponent } from 'vue'
import { logout } from '@/auth'

export default defineComponent({
  setup() {
    const [isMenuVisible, toggleMenu] = useToggle()

    const containerRef = useOnClickedOutside(() => toggleMenu(false))

    return {
      ...useMyUser(),
      containerRef,
      toggleMenu,
      isMenuVisible,
    }
  },
  methods: {
    logout,
  },
})
</script>

<style lang="scss" scoped>
.avatar-container {
  position: relative;
  align-self: stretch;

  .job-title {
    font-size: 9px;
  }
}

.avatar {
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;

  border-left: 1px solid #e3e3e3;

  cursor: pointer;

  padding: 10px 20px;

  @media (max-width: 800px) {
    padding: 10px 20px;
  }
}

.left-block {
  height: 60px;
  display: block;

  border-radius: 50%;
  border: 2px solid #ddd;
}

.right-block {
  margin-top: 10px;
  margin-left: 10px;

  @media (max-width: 800px) {
    display: none;
  }
}

.dropdown {
  position: absolute;
  top: 100%;
  right: 0;
  width: 100%;

  background: white;
  box-shadow: 0 30px 40px -10px #dde2e4;
  border: 1px solid #eee;

  @media (max-width: 800px) {
    min-width: 300px;
  }

  &__logout {
    padding: 20px 30px;
    display: flex;
    align-items: center;

    width: 100%;
    font-size: 16px;

    transition: background 0.2s;

    &:hover {
      background: #eee;
    }

    img {
      margin-right: 20px;
    }
  }
}
</style>
