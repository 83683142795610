import { onMounted, onUnmounted, ref } from 'vue'

export function useLoopedTimer(
  countdownSeconds: number,
  onReset: VoidFunction
) {
  const timerRef = ref(countdownSeconds)

  let startTime = Date.now()

  const getTimeLeft = () => {
    const elapsedSeconds = (Date.now() - startTime) / 1000
    return Math.round(countdownSeconds - elapsedSeconds)
  }

  const onInterval = () => {
    if (getTimeLeft() < 0) {
      startTime = Date.now()
    }

    if (getTimeLeft() <= 0) {
      onReset()
    }

    timerRef.value = getTimeLeft()
  }

  let intervalId = 0

  onMounted(() => {
    intervalId = window.setInterval(onInterval, 1000)
  })

  onUnmounted(() => {
    window.clearInterval(intervalId)
  })

  return { timer: timerRef }
}
