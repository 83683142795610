export function getQueryParams() {
  return Object.fromEntries(
    new URLSearchParams(window.location.search).entries()
  )
}

export type QueryParams = { [key: string]: string | number | undefined | null }

export function withParams(path: string, queryParams: QueryParams = {}) {
  return path + generateQueryString(queryParams)
}

function generateQueryString(queryParams: QueryParams) {
  const search = new URLSearchParams()

  for (const [key, value] of Object.entries(queryParams)) {
    if (value !== null && value !== undefined) {
      search.set(key, String(value))
    }
  }

  const searchString = search.toString()
  if (searchString === '') return ''

  return '?' + searchString
}
