<template>
  <div class="timer">
    <div class="timer__value">
      {{ formattedTime }}
    </div>
    <div class="timer__text">UPDATE</div>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},

  props: {
    timer: {
      type: Number,
      required: true,
    },
  },

  setup() {},

  computed: {
    formattedTime() {
      const formattedSeconds = this.timer < 10 ? '0' + this.timer : this.timer
      return `00:${formattedSeconds}`
    },
  },
})
</script>

<style lang="scss" scoped>
.timer {
  position: absolute;
  top: 1.5em;
  right: 2em;

  width: 6em;
  // display: flex;
  // flex-direction: column;
  // justify-content: center;
  // align-items: center;

  &__value {
    font-family: Oswald;
    font-weight: 600;
    font-size: 2.1em;

    color: #0ce764;
  }

  &__text {
    margin-top: 0.1em;
    margin-left: 0.475em;
    font-family: Lato;
    font-weight: 900;
    color: #b0b0b0;
    font-size: 1em;
    // text-align: center;
  }
}
</style>
