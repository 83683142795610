
import { ILeaderboard, ILeaderboardEntry } from '@/api/types'
import LeaderboardPlayerItem from '@/components/LeaderboardPlayerItem.vue'
import LeaderboardPodiumItem from '@/components/LeaderboardPodiumItem.vue'
import LoopedTimer from '@/components/LoopedTimer.vue'
import { useDynamicallyScaledFontSize } from '@/hooks/useDynamicallyScaledFontSize'
import { Company } from '@/types/Company'
import { defineComponent, PropType } from 'vue'

export default defineComponent({
  components: {
    LeaderboardPodiumItem,
    LeaderboardPlayerItem,
    LoopedTimer,
  },

  props: {
    leaderboard: {
      type: Object as PropType<ILeaderboard>,
      required: true,
    },
    title: {
      type: String,
      required: false,
      default: undefined,
    },
    userDisplayFormat: {
      type: Object as PropType<Company['user_display_name_format']>,
      required: false,
      default: undefined,
    },
    timer: {
      type: Number,
      required: true,
    },
    isLoading: {
      type: Boolean,
      required: true,
    },
  },

  setup() {
    return { parentRef: useDynamicallyScaledFontSize() }
  },

  computed: {
    podiumEntries(): ILeaderboardEntry[] {
      return this.leaderboard.entries.filter((e) => e.index < 3)
    },

    playerEntries(): ILeaderboardEntry[] {
      return this.leaderboard.entries.filter((e) => e.index >= 3)
    },
  },
})
