export function isProductScorable(productType: string) {
  const scorableProducts = new Set([
    'skill',
    'behavior',
    'process',
    'act',
    'workflow',
    'operations',
  ])
  return scorableProducts.has(productType)
}
