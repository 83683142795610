<template>
  <div v-if="error" class="feedback-container">
    <div class="subtitle-2">
      {{ error.message }}
    </div>
  </div>

  <div v-else-if="!leaderboard" class="page-loading">
    <AttensiLoadingSpinner />
  </div>

  <div v-else-if="isLeaderboardEmpty" class="feedback-container">
    <div class="subtitle">
      {{ $t('leaderboard.empty') }}
    </div>
  </div>

  <template v-else>
    <Leaderboard
      :leaderboard="leaderboard"
      :title="title"
      :timer="timer"
      :is-loading="isLoading"
      :user-display-format="userDisplayFormat"
    />
  </template>
</template>

<script lang="ts">
import Leaderboard from '@/components/Leaderboard.vue'
import { useLiveLeaderboard } from '@/hooks/useLiveLeaderboard'
import { useLoopedTimer } from '@/hooks/useLoopedTimer'
import { numberOrUndefined } from '@/utils/numberOrUndefined'
import { AttensiLoadingSpinner } from '@attensi/materia'
import { defineComponent, ref } from 'vue'
import { useRoute } from 'vue-router'

export default defineComponent({
  components: { Leaderboard, AttensiLoadingSpinner },
  setup() {
    const { params, query } = useRoute()

    const title = query.title as string | undefined
    const userDisplayFormat = query.userDisplayFormat as string | undefined
    const token = params.token as string
    const limit = numberOrUndefined(query.limit as string | undefined)

    const isLoading = ref(false)

    const { leaderboard, error, mutate } = useLiveLeaderboard(token, limit)

    const refreshLeaderboard = async () => {
      isLoading.value = true

      await mutate()

      setTimeout(() => {
        isLoading.value = false
      }, 200)
    }

    const { timer } = useLoopedTimer(30, refreshLeaderboard)

    window.document.title = title ?? 'Attensi Leaderboard'

    return {
      leaderboard,
      userDisplayFormat,
      error,
      title,
      timer,
      isLoading,
    }
  },

  computed: {
    isLeaderboardEmpty(): boolean {
      return this.leaderboard?.entries.length === 0
    },
  },
})
</script>
