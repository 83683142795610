
import { defineComponent } from 'vue'

export default defineComponent({
  components: {},

  props: {
    timer: {
      type: Number,
      required: true,
    },
  },

  setup() {},

  computed: {
    formattedTime() {
      const formattedSeconds = this.timer < 10 ? '0' + this.timer : this.timer
      return `00:${formattedSeconds}`
    },
  },
})
