import { merge } from 'lodash-es'
import { createSession, logout } from '@/auth'
import { RemoteSession } from './types'


export async function authFetch(
  input: RequestInfo,
  init?: RequestInit
): Promise<Response> {
  const session = await createSession()


  const res = await fetchWithAuthHeaders(input, init, session)

  if (res.status === 401) {
    await logout()
  }

  if (!res.ok) {
    console.error(res)
    throw new Error(await extractErrorMessage(res))
  }

  return res
}

async function extractErrorMessage(res: Response) {
  const text = await res.text()

  if (!text) return 'Error - HTTP status code ' + res.status

  try {
    const { errors } = JSON.parse(text)
    return 'Error - ' + Object.values(errors).join(', ')
  } catch (e) {
    return text
  }
}

async function fetchWithAuthHeaders(
  input: RequestInfo,
  init: RequestInit | undefined,
  session: RemoteSession | undefined
): Promise<Response> {
  if (session === undefined) {
    throw new Error('Missing session')
  }


  return fetch(input, merge(getAuthHeaders(session), init))
}

function getAuthHeaders({ accessToken, apiToken }: RemoteSession) {
  return {
    headers: {
      Authorization: `Bearer ${accessToken}`,
      'API-Token': apiToken,
    },
  }
}
