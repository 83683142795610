import { Ref, ref, watchEffect } from 'vue'

export function anyError(...errors: Ref[]): Ref {
  const firstError = ref()

  watchEffect(() => {
    firstError.value = errors.find((e) => e.value !== undefined)?.value
  })

  return firstError
}
