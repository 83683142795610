import { i18n } from './i18n'
import { loadLocale } from './api/loadLocale'
import { pickUserLocale } from './pickUserLocale'
import { ProjectManifest } from './types/ProjectManifest'
import { disableImplicitFallback } from './utils/disableImplicitFallback'

export async function configureI18n(manifest: ProjectManifest) {
  const supportedLocales = Object.keys(manifest.locales)
  const fallbackLocale = manifest.baseLocale

  const locale = pickUserLocale(supportedLocales, fallbackLocale)

  await loadLocale(manifest, locale)
  i18n.global.locale = disableImplicitFallback(locale)

  if (fallbackLocale !== locale) {
    await loadLocale(manifest, fallbackLocale)
    i18n.global.fallbackLocale = disableImplicitFallback(fallbackLocale)
  }
}
