import { Ref, ref } from 'vue'

type ToggleValue = (value?: boolean) => void

export function useToggle(initialValue = false): [Ref<boolean>, ToggleValue] {
  const valueRef = ref(initialValue)

  const toggleValue: ToggleValue = (value) => {
    if (typeof value === 'boolean') {
      valueRef.value = value
    } else {
      valueRef.value = !valueRef.value
    }
  }

  return [valueRef, toggleValue]
}
