export interface IProduct {
  id: number
  title: string | null
  description: string
  product_type: string
}

export interface IGroup {
  id: number
  name: string
  group_type: string
}

export interface IScenarioCategory {
  scenario_ids: number[]
}

export type Language = 'en-US' | 'nb-NO'
export type LanguageTexts = Record<Language, string | undefined>

export interface IScenario {
  title: LanguageTexts
  description: LanguageTexts | null
}

export interface ILeaderboardEntry {
  user: {
    first_name?: string
    last_name?: string
    username?: string
    image?: string | null
  }
  value: number
  index: number
  rank: number
}

export interface ILeaderboard {
  entries: ILeaderboardEntry[]
}

export enum GroupType {
  LEADERBOARD = 'leaderboard',
}

export interface RemoteSession {
  accessToken: string
  apiToken: string
  companyNameKey: string
  locale: string
  rememberMe: boolean
  subdomain: string
}

